<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <span @click="$router.go(-1)"
          >{{$isExamSearch()?'报名查询':'报名管理'}}</span
        >/{{$isExamSearch()?'考生详情':'报名审核'}}
      </template>
      <template #input>
        <a-input placeholder="请输入关键字" v-model="queryParams.name" />
        <a-select
          placeholder="学习进度"
          v-model="queryParams.studyStatus"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">未学习</a-select-option>
          <a-select-option :value="2">未学完</a-select-option>
          <a-select-option :value="3">已学完</a-select-option>
        </a-select>
        <a-button type="primary" class="btn" @click="onSearch">搜索</a-button>
        <a-button v-if="!$isExamSearch()" type="primary" class="btn" @click="isValidityShow = true" v-hasPermi="['exam:signupuser:add']"
          >新建报名人员</a-button
        >
        <a-button
          v-if="!$isExamSearch()"
          type="primary"
          class="btn"
          :loading="PreventLoad"
          :disabled="queryParams.auditStatus != 1"
          @click="onBatchExamine"
          v-hasPermi="['exam:signup:updatestatus']"
          >批量审核</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <h4 class="title_big">
        <span style="margin-right: 10px">{{ exam.name }}</span>
        <span>场次：{{ exam.time }}</span>
      </h4>
      <a-tabs :default-active-key="queryParams.auditStatus" @change="callback">
        <a-tab-pane key="1" tab="未审核"></a-tab-pane>
        <a-tab-pane key="2" tab="已审核"></a-tab-pane>
        <a-tab-pane key="3" tab="已驳回"></a-tab-pane>
      </a-tabs>
      <a-table
        class="table-template"
        :rowKey="(item) => item.signupUserId"
        :columns="tableColumns"
        :data-source="tableData"
        :scroll="{ x: 1500 }"
        :loading="loading"
        @change="onPage"
        :row-selection="{
          selectedRowKeys: selectedSignUpRowKeys,
          onChange: select,
          columnTitle: '选择',
          getCheckboxProps: (record) => ({
            props: {
              disabled: queryParams.auditStatus != 1,
            },
          }),
        }"
        :pagination="{
          total: total,
          current: queryParams.current,
          defaultPageSize: queryParams.size,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="footer">
          <a-checkbox
            @change="onChange"
            :disabled="queryParams.auditStatus != 1 || !tableData.length"
            :checked="isChecked && queryParams.auditStatus == 1"
            class="allSellect"
          >
            全选
          </a-checkbox>
        </template>
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (queryParams.current - 1) * queryParams.size + i + 1 }}
          </div>
        </template>

        <!-- 个人证件照 -->
        <template slot="image" slot-scope="text">
          <viewer v-if="text">
            <img class="pictrue" :src="text" alt="" />
          </viewer>
          <span v-else>-</span>
        </template>
        <!-- 总成绩 -->
        <template slot="totalScore" slot-scope="text">
          <span >{{ text ? text : '-' }}</span>
        </template>
        <!-- 考试状态 -->
        <template slot="examStatus" slot-scope="text">
          <span
            v-if="text"
            class="state_all"
            :class="
              text == '考试中'
                ? 'state_color_yellow'
                : text == '已通过'
                ? 'state_color_green'
                : text == '未通过'
                ? 'state_color_red'
                : ''
            "
            >{{ text }}</span
          >
          <span v-else>-</span>
        </template>
        
        <template slot="studyProgress" slot-scope="text">
          <span >{{ text }}%</span>
        </template>
        <template slot="replaStatus" slot-scope="text">
          <span v-if="text" class="state_all">{{ text }}</span>
          <span v-else>-</span>
        </template>

        <template slot="auditTime" slot-scope="text">
          {{ queryParams.auditStatus != 1 ? text : "-" }}
        </template>
        <template slot="operation" slot-scope="text">
          <div class="btn_router_link">
            <a
              v-show="queryParams.auditStatus != 1"
              to
              @click="onSignUpExamine(1, text.signupUserId)"
              >报名信息
            </a>
            <!-- <a
              v-hasPermi="['exam:examine:update']"
              v-if="!$isExamSearch()"
              v-show="queryParams.auditStatus == 2"
              to
              @click="onModal(1, text)"
              >| 考试码
            </a> -->
            <a
              v-hasPermi="['exam:examine:update']"
              v-if="!$isExamSearch()"
              v-show="queryParams.auditStatus == 2"
              to
              @click="getTimeList(text)"
              >| 更换场次
            </a>
            <a
              v-hasPermi="['exam:examine:updatestatus']"
              v-if="!$isExamSearch()"
              v-show="queryParams.auditStatus == 2"
              to
              @click="onModal(2, text)"
              >| 重置考试状态
            </a>
            <a
              v-show="queryParams.auditStatus == 1"
              to
              @click="onSignUpExamine(2, text.signupUserId)"
              >{{ !$isExamSearch()?"审核":"报名信息" }}
            </a>
            <a @click="auditStatusChange(text)" v-if="!$isExamSearch()" v-hasPermi="['exam:signup:updatestatus']"> | 更换审核状态</a>
            <template v-if="(queryParams.auditStatus==2 || text.replaStatus) && text.replaStatus"> | </template>
            <a v-if="text.replaStatus" @click="querylist(text)">换场记录</a>
            <template v-if="queryParams.auditStatus==2"> | </template>
            <a v-if="queryParams.auditStatus==2" @click="gooR(text)">操作记录</a>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 新建报名人员 -->
    <a-modal v-model="isValidityShow" align="center" title="新建报名人员">
      <div class="all_content_box">
        <a-row class="a-row-item">
          <a-col :span="6" class="a-row-left"
            ><span class="name all_required" style="line-height: 32px"
              >姓名/身份证：</span
            ></a-col
          >
          <a-col :span="15">
            <a-auto-complete
              style="width: 100%"
              v-model="userParams.keyword"
              placeholder="输入搜索关键字"
              option-label-prop="value"
              @search="onSearchRole"
              :defaultActiveFirstOption="false"
              :allowClear="true"
            >
              <a-spin
                v-if="fetching"
                class="auto-spin"
                slot="notFoundContent"
                size="small"
              />
              <template slot="dataSource">
                <a-select-option
                  @click="onSelectRole(item)"
                  v-for="(item, index) in list"
                  :key="index + ''"
                  :value="item.nameIdcard"
                >
                  <a-row type="flex" justify="space-between" align="middle">
                    <a-col>{{ item.nameIdcard }}</a-col>
                  </a-row>
                </a-select-option>
              </template>
            </a-auto-complete>
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="validityLoad" @click="onSignOk">确认</a-button>
        <a-button @click="isValidityShow = false">取消</a-button>
      </template>
    </a-modal>
    <!-- 更换场次 -->
    <a-modal v-model="modifyVisible" title="更换考试场次">
      <div class="all_content_box">
        <a-table
          class="table-template"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            columnTitle: '选择',
            type: 'radio',
            fixed: true,
            onChange: onSelectTimeChange,
          }"
          :rowKey="(item) => item.timeId"
          :columns="columns2"
          :data-source="timeData"
          :pagination="false"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ i + 1 }}
            </div>
          </template>
        </a-table>
      </div>
      <template slot="footer">
        <div style="margin-top: 20px">
          <a-button type="primary" :loading="loading" @click="onModifyTime">确认</a-button>
          <a-button @click="modifyVisible = false">取消</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 历史场次 -->
    <a-modal v-model="modifyVisibles" title="换场记录">
      <div class="all_content_box">
        <a-table
          class="table-template"
          :columns="columns3"
          :data-source="timeData"
          :pagination="false"
          :scroll="{ x: 1200 }"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ i + 1 }}
            </div>
          </template>
        </a-table>
      </div>
      <template slot="footer">
        <div style="margin-top: 20px">
          <!-- <a-button v-if="iftitle" type="primary" @click="onModifyTime"
            >确认</a-button
          >
          <a-button @click="modifyVisibles = false">取消</a-button> -->
        </div>
      </template>
    </a-modal>
    <!-- 考试码  重置考试 弹窗 -->
    <a-modal
      v-model="isModal"
      align="center"
      :title="modalType == 1 ? '考试码' : '重置考试状态'"
    >
      <div class="all_content_box">
        <div class="code-tips" v-show="modalType == 1">
          <p >是否向考生发送考试码？</p><a-button type="primary" :loading="loading" @click="onReset">发送</a-button>
          <p class="up">查看考生考试码？</p><a-button type="primary" :loading="loading" @click="onViewCode">查看</a-button>
        </div>
        <p class="tips" v-show="modalType == 2">
          是否向将该考生的状态重置为未考试状态？
        </p>
      </div>
      <template slot="footer">
        <div v-if="modalType == 2">
          <a-button type="primary" :loading="loading" @click="onReset">确认</a-button>
          <a-button @click="isModal = false">取消</a-button>
        </div>
        <div v-else>         
        </div>
      </template>
    </a-modal>
    <!-- 更换审核状态 -->
    <a-modal v-model="isNumberShow" align="center" title="更换审核状态">
      <div class="all_content_box">
        <a-row class="a-row-item">
          <a-col :span="5" class="a-row-left"
            ><span class="name">审核操作：</span></a-col
          >
          <a-col :span="13">
            <a-radio-group v-model="examineValue">
              <a-radio :value="1">未审核</a-radio>
              <a-radio :value="2">已通过</a-radio>
              <a-radio :value="3">已驳回</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-if="examineValue == 3">
          <a-col :span="5" class="a-row-left"
            ><span class="name">驳回原因：</span></a-col
          >
          <a-col>
            <a-textarea
              v-model="rejecReason"
              style="width: 75%"
              placeholder="请输入驳回原因，将在用户终端显示。"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-show="examineValue == 2" v-if="false">
          <a-col :span="5" class="a-row-left"
            ><span class="name">是否发送短信：</span></a-col
          >
          <a-col>
            <a-radio-group v-model="messageValue">
              <a-radio style="width: 80px" :value="1">否</a-radio>
              <a-radio :value="2">是</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row
          class="a-row-item"
          v-show="examineValue == 2 && messageValue == 2"
          v-if="false"
        >
          <a-col :span="5" class="a-row-left"
            ><span class="name">短信发送内容：</span></a-col
          >
          <a-col>
            <a-textarea
              style="width: 70%"
              placeholder="请输入驳回原因"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="loading" @click="onExamine"
          >确认</a-button
        >
        <a-button @click="isNumberShow = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    width: "130px",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    width: "130px",
    dataIndex: "mobile",
  },
  {
    title: "身份证号",
    align: "center",
    width: "180px",
    dataIndex: "idcard",
  },
  {
    title: "个人证件照",
    align: "center",
    width: "180px",
    dataIndex: "certifiMobile",
    scopedSlots: { customRender: "image" },
  },
  {
    title: "报名时间",
    align: "center",
    width: "180px",
    dataIndex: "createTime",
  },
  {
    title: "审核时间",
    align: "center",
    width: "180px",
    dataIndex: "auditTime",
    scopedSlots: { customRender: "auditTime" },
  },
  {
    title: "报名来源",
    align: "center",
    width: "180px",
    dataIndex: "orgName",
  },
  {
    title: "学习进度",
    align: "center",
    width: "100px",
    dataIndex: "studyProgress",
    scopedSlots: { customRender: "studyProgress" },

  },
  {
    title: "补考次数",
    align: "center",
    width: "100px",
    dataIndex: "markNum",
  },
  {
    title: "客观成绩",
    align: "center",
    width: "100px",
    dataIndex: "examScore",
  },
  {
    title: "总成绩",
    align: "center",
    width: "100px",
    dataIndex: "totalScore",
    scopedSlots: { customRender: "totalScore" },
  },
  {
    title: "考试状态",
    align: "center",
    width: "120px",
    dataIndex: "examStatus",
    scopedSlots: { customRender: "examStatus" },
  },
  {
    title: "换场状态",
    align: "center",
    width: "100px",
    scopedSlots: { customRender: "replaStatus" },
    dataIndex: "replaStatus",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "230px",
    scopedSlots: { customRender: "operation" },
  },
];
const columns2 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考试日期",
    align: "center",
    dataIndex: "examDate",
  },
  {
    title: "考试场次",
    align: "center",
    dataIndex: "examDateTime",
  },
];
const columns3 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "原场次",
    align: "center",
    dataIndex: "currentTime",
  },
  {
    title: "更换场次",
    align: "center",
    dataIndex: "replaTime",
  },
  {
    title: "交卷时间",
    align: "center",
    dataIndex: "submitPaperTime",
  },

  {
    title: "总成绩",
    align: "center",
    dataIndex: "totalScore",
  },
  {
    title: "换场操作者",
    align: "center",
    dataIndex: "operator",
  },
  {
    title: "操作时间",
    align: "center",
    dataIndex: "createTime",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      columns2,
      columns3,
      tableColumns:[],
      tableData: [], // 列表数据
      timeData: [], //场次列表or换场记录
      loading: false, // 列表加载
      PreventLoad:false,
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      examineValue: 1,
      rejecReason: "", //驳回原因
      messageValue: 2,
      validityLoad: false, // 新增报名人员加载
      isValidityShow: false, // 新增报名人员
      isNumberShow: false, // 更改审核状态
      isModal: false,
      modifyVisible: false, // 更换考试场次
      modifyVisibles: false, // 历史记录
      userId: "",
      modifyTimeName: "",
      examineeId: "",
      queryParams: {
        current: 1, // 页码
        size: 10, // 页数
        name: "",
        auditStatus: 1, // 审核状态  1.未审核 2.已审核 3.已驳回
        signupId: "", // 报名id
        timeId: "", // 场次id
        studyStatus: undefined, // 学习进度 1.未学习 2.未学完 3.已学完
      },

      userParams: {
        pageNum: 1, //页码
        pageSize: 10, // 页数
        keyword: "",
      },

      exam: {
        name: "",
        time: "",
      },
      signupUserId: "", // 报名人员id
      list: [],
      fetching: false,
      selectUser: {},

      modalType: 1, // 1.考试码  2.重置考试状态
      exmineeName: "",

      modifyTimeId: "", // 需要更换后的场次id

      selectedRowKeys: [],
      selectedSignUpRowKeys: [],
      isChecked: false,
    };
  },
  // 事件处理器
  methods: {
    // 查看用户操作记录
    gooR(e) {
      this.$router.push({
        path: "/admin/Examination/operationRecord",
        query: { examineeId: e.examineeId },
      });
    },
    // 分页
    onPage(e) {
      this.queryParams.current = e.current;
      this.queryParams.size = e.pageSize;
      this.getManageList();
    },
    // tab切换
    callback(e) {
      this.queryParams.auditStatus = e;
      this.examineValue = parseInt(e);
      this.getManageList();
      if(e == 1){
        this.tableColumns = JSON.parse(JSON.stringify(this.columns))
        let columns = [
          {
            title: "审核状态",
            align: "center",
            width: "180px",
            dataIndex: "auditStatus",
          }
        ]
        this.tableColumns.splice(-5, 0, ...columns)
      } else {
        this.tableColumns = JSON.parse(JSON.stringify(this.columns))
      }
    },
    // 报名信息 / 审核
    onSignUpExamine(operation, id) {
      this.$router.push({
        path: this.$isExamSearch()?"/admin/ExamSearch/AuditDetails":"/admin/Examination/AuditDetails",
        query: { operation: operation, id: id },
      });
    },

    onSignOk() {
      if (!this.selectUser.userId) {
        this.$message.warning("查无此人");
        return;
      }
      this.validityLoad = true
      this.$ajax({
        method: "post",
        url: "/hxclass-management/exam/signup/user/save",
        params: {
          signupId: this.queryParams.signupId, // 	考试报名id
          timeId: this.queryParams.timeId, //场次id
          timeName: this.exam.time, //	场次名称
          userId: this.selectUser.userId, //用户id
          auditStatus: 1, // 未审核
          birthday: this.selectUser.birthday, // 	生日
          education: this.selectUser.degree, // 	学历
          exmineeName: this.selectUser.name, // 	考生名称
          idcard: this.selectUser.idNo, // 	身份证号
          mobile: this.selectUser.mobile, // 	手机号
          name: this.selectUser.name, // 	姓名
          post: this.selectUser.position, // 	职务/职称
          sex: this.selectUser.sex == 1 ? 1 : 0, // 	性别
          workUnit: this.selectUser.workName, // 工作单位
        },
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.isValidityShow = false;
            this.selectUser = {};
            this.userParams.keyword = "";
            this.getManageList();
            this.$message.success("添加成功");
          } else {
            this.$message.warning(res.message);
          }
          this.validityLoad = false
        })
        .catch((error) => {
          if (error) {
            this.validityLoad = false
            this.$message.warning("系统错误");
          }
        });
    },
    // 打印选中人员
    onSelectRole(e) {
      this.selectUser = e;
    },
    // 检索人员
    onSearchRole(val) {
      this.fetching = false;
      this.list = [];
      if (!val) {
        clearTimeout(this.tiemID);
        return;
      }
      this.$nextTick(function () {
        if (val !== "") {
          this.fetching = true;
          const _this = this;
          clearTimeout(this.tiemID);
          this.tiemID = setTimeout(function () {
            _this.selectUser = {};
            _this
              .$ajax({
                method: "get",
                url: "/hxclass-management/user/info/list/keyword",
                params: _this.userParams,
              })
              .then((res) => {
                if (res.code == 200 && res.success) {
                  res.data.records.forEach((element) => {
                    element.nameIdcard = element.name + "  " + element.idNo;
                  });
                  _this.list = res.data.records;
                  _this.fetching = false;
                }
              });
          }, 400);
        }
      });
    },

    // 打开考试码 或 重置考试状态  弹窗
    onModal(i, e) {
      if (i == 1 && (e.examStatus == "未考试" || !e.examStatus)) {
        // 如果该考生状态已经开始考试 或 考完 就不允许更改审核状态
        this.examineeId = e.examineeId;
        this.exmineeName = e.name;
        this.modalType = i;
        this.isModal = true;
      } else if (
        i == 2 &&
        (e.examStatus == "考试中" || e.examStatus == "未通过" || e.examStatus == "成绩待发布")
      ) {
        // 如果该考生状态已经开始考试 或 考完 就不允许更改审核状态
        this.examineeId = e.examineeId;
        this.exmineeName = e.name;
        this.modalType = i;
        this.isModal = true;
      } else {
        this.$message.warning("当前考试状态不允许操作");
      }
    },
    // 高级检索
    onSearch() {
      this.queryParams.current = 1;
      this.getManageList();
    },
    // 查询列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/exam/signup/user/list",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    // 打开更换场次弹窗
    onTimeChange(text) {
      // if (text.examStatus == "未考试" || !text.examStatus) {
      // 如果该考生状态已经开始考试 或 考完 就不允许更改审核状态
      this.exmineeName = text.name; // 获取考生名称
      this.modifyVisible = true;
      this.signupUserId = text.signupUserId;
      // } else {
      //   this.$message.warning("当前考试状态不允许操作");
      // }
    },
    // 场次列表
    getTimeList(text) {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/exam/signup/change/time/list",
        params: {
          signupId: this.queryParams.signupId,
          signupUserId: text.signupUserId,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          if (res.data.length) {
            this.timeData = res.data;
            this.onTimeChange(text);
          } else {
            this.$message.error("暂无场次可更换");
          }
        } else {
          this.timeData = [];
        }
      });
    },
    // 更换场次场次列表
    querylist(text) {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/exam/repla/list",
        params: {
          signupId: this.queryParams.signupId,
          userId: text.userId,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          if (res.data.length) {
            this.timeData = res.data;
            this.modifyVisibles = true;

            // this.onTimeChange(text);
          } else {
            this.$message.error("没有记录");
          }
        } else {
          this.timeData = [];
        }
      });
    },
    // 批量审核
    onBatchExamine() {
      if (!this.selectedSignUpRowKeys.length) {
        this.$message.warning("请至少选择一项需要审核的学员");
        return;
      }
      let _this = this;
      this.$confirm({
        title: "确定要批量审核学员吗?",
        okText: "确认",
        onOk() {
          _this.PreventLoad=true
          _this
            .$ajax({
              url:
                "/hxclass-management/exam/signup/batch/audit?signupUserIds=" +
                _this.selectedSignUpRowKeys.toString() +
                "&signUpId=" +
                Number(_this.$route.query.signupId),
              method: "put",
              // params: {
              //   signupUserIds: _this.selectedSignUpRowKeys.toString()
              // },
            })
            .then((res) => {
              _this.PreventLoad=false
              if (res.code == 200 && res.success) {
                _this.isChecked = false;
                _this.selectedSignUpRowKeys = [];
                _this.$message.success("审核成功");
                _this.getManageList();
              } else {
                _this.$message.warning(res.message);
              }
            });
        },
        onCancel() {},
      });
    },
    //全选
    onChange(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        let idArry = this.tableData.map((item) => {
          return item.signupUserId;
        });
        this.selectedSignUpRowKeys = idArry;
      } else {
        this.isChecked = false;
        this.selectedSignUpRowKeys = [];
      }
    },
    // 批量审核存放报名id
    select(selectedRowKeys) {
      this.selectedSignUpRowKeys = selectedRowKeys;
      if (this.selectedSignUpRowKeys.length == this.tableData.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      this.selectedSignUpRowKeys = selectedRowKeys;
    },

    // 强制更改审核状态
    auditStatusChange(text) {
      this.examineValue = parseInt(this.queryParams.auditStatus);
      this.signupUserId = text.signupUserId;
      this.userId = text.userId;
      if (text.examStatus == "未考试" || !text.examStatus) {
        // 如果该考生状态已经开始考试 或 考完 就不允许更改审核状态
        this.isNumberShow = true;
      } else {
        this.$message.warning("当前考试状态不允许操作");
      }
    },
    // 审核报名
    onExamine() {
      if(this.examineValue == this.queryParams.auditStatus) {
        this.$message.warning("请审核为其他考试状态");
        return
      }
      if (this.examineValue == 1 || this.examineValue == 2) {
        this.rejecReason = "";
      }
      this.loading = true;
      this.$ajax({
        method: "put",
        url:
          "/hxclass-management/exam/signup/user/audit?auditStatus=" +
          this.examineValue +
          "&signupUserId=" +
          this.signupUserId +
          "&rejecReason=" +
          this.rejecReason +
          "&examineeUserId=" +
          this.userId,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.getManageList();
          this.isNumberShow = false;
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 发生考试码 -- 重置考试状态
    onReset() {
      let examcodeUrl =
        "/hxclass-management/exam/examinee/send/examcode?examineeId=" +
        this.examineeId; // 发送考试码
      let editUrl = "/hxclass-management/exam/examinee/examinee/edit"; // 重置考试状态
      let object = {
        examStatus: 1,
        examineeId: this.examineeId,
        exmineeName: this.exmineeName,
        examName: this.exam.name,
      };
      this.loading = true;
      this.$ajax({
        method: this.modalType == 1 ? "post" : "put",
        url: this.modalType == 1 ? examcodeUrl : editUrl,
        params: this.modalType == 2 ? object : {}, // 如果是重置考生状态，用对象传参
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (this.modalType == 2) {
            this.getManageList();
          } // 重置考生考试状态后 刷新列表
          this.isModal = false;
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
        this.loading = false;
      });
    },
    // 查看考试码
    onViewCode() {
      this.loading = true;
      this.$ajax({
        method: "GET",
        url: "/hxclass-management/exam/examinee/getExamineeExamCode",
        params: {
          examineeId: this.examineeId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success && res.data) {
          this.$message.success("考试码：" + res.data);
        } else {
          this.$message.warning("暂无考生码");
        }
        this.loading = false;
      });
    },
    // 选择场次
    onSelectTimeChange(e, v) {
      this.modifyTimeName = v[0].examDateTime; // 获取最新考试场次
      this.modifyTimeId = e[0];
      this.selectedRowKeys = e;
    },
    onModifyTime() {
      if (!this.modifyTimeId) {
        this.$message.warning("请选择需要更换的场次");
        return;
      }
      this.loading = true;
      this.$ajax({
        method: "put",
        url: "/hxclass-management/exam/signup/signup/user/edit/time",
        params: {
          signupUserId: this.signupUserId,
          timeId: this.modifyTimeId, // 最新场次id
          exmineeName: this.exmineeName, // 考生名称
          timeName: this.modifyTimeName, // 最新考试场次
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.modifyTimeId = "";
          this.modifyTimeName = "";
          this.selectedRowKeys = [];
          this.getManageList();
          this.modifyVisible = false;
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取暂存的报名搜索条件
    let signUpParamsObj = localStorage.getItem("signUpParamsObj")
      ? JSON.parse(localStorage.getItem("signUpParamsObj"))
      : null;
    if (signUpParamsObj) {
      this.queryParams = signUpParamsObj;
    }

    this.queryParams.signupId = this.$route.query.signupId;
    this.queryParams.timeId = this.$route.query.timeId;

    this.exam.name = this.$route.query.examName; // 获取考试名称
    this.exam.time = this.$route.query.time; // 获取考试场次


    this.tableColumns = JSON.parse(JSON.stringify(this.columns))
    let columns = [
      {
        title: "审核状态",
        align: "center",
        width: "180px",
        dataIndex: "auditStatus",
      }
    ]
    this.tableColumns.splice(-5, 0, ...columns)
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.getManageList();
  },
  beforeDestroy() {
    localStorage.setItem("signUpParamsObj", JSON.stringify(this.queryParams));
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    modifyVisible() {
      if (!this.modifyVisible) {
        this.selectedRowKeys = [];
        this.modifyTimeId = "";
        this.modifyTimeName = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.title_big {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #000;
}
.table-template {
  margin-top: 0;
}
.pictrue {
  width: 50px;
  height: 60px;
  background-repeat: no-repeat;
  object-fit: cover;
}
/deep/ .ant-modal-body {
  padding-bottom: 0;
}
.all_content_box {
  .a-row-item {
    margin-bottom: 20px;
    .a-row-left {
      margin-right: 10px;
    }
    .name {
      width: 100%;
      display: inline-block;
      text-align: right;
    }
    .right {
      width: 100%;
    }
  }
  .tips {
    font-size: 17px;
    color: #333;
    margin: 0 0 20px;
  }
  .code-tips {
    font-size: 17px;
    color: #333;
    p{
      margin-bottom: 5px;
    }
    .up{
      margin-top: 20px;
    }
  }
}
/deep/ .ant-modal-footer {
  text-align: center;
  padding-bottom: 30px;
}
</style>
